<template>
	<div class="address-input">
		<input
			type="text"
			:value="props.context._value"
			@input="handleInput"
			:class="context.classes.input"
			@focusout="showResult = false"
		/>
		<div ref="dropdown" class="dropdown-list fadein" v-if="showResult">
			<button
				type="button"
				v-for="(result, index) in resultList"
				:key="index"
				@click="setValue(result.address)"
			>
				<div class="icon fas fa-map-marker-alt"></div>
				<span class="address">
					{{ result.address.string }}
				</span>
			</button>
		</div>
	</div>
</template>

<script setup>
const props = defineProps({
	context: Object,
});
const resultList = ref([]);
const dropdown = ref();
const showResult = ref(false);

const fetchUrl = `https://webservices-pub.bpost.be/ws/ExternalMailingAddressProofingCSREST_v1/address/autocomplete`;

const getAddressList = async (q) => {
	showResult.value = true;

	const url = `${fetchUrl}?id=2&q=${encodeURIComponent(q)}`;
	const $fetch = await fetch(url);
	const json = await $fetch.json();

	resultList.value = json.response.topSuggestions.filter(
		(s) => s.address.isComplete
	);
};

let timer;

const setValue = (obj) => {
	const value = obj.searchBarString;

	props.context._value = value;
	props.context.node.input(value);

	dropdown.value.classList.remove("fadein");
	dropdown.value.classList.add("fadeout");

	setTimeout(() => {
		showResult.value = false;
		resultList.value = [];
	}, 1000)
};

const handleInput = (e) => {
	const value = e.target.value;

	props.context.node.input(value);

	clearTimeout(timer);

	timer = setTimeout(() => {
		getAddressList(value);
	}, 500);
};
</script>

<style lang="scss" scoped>
.address-input {
	position: relative;
	width: 100%;

	.dropdown-list {
		position: absolute;
		width: 100%;
		border-radius: var(--border-radius);
		box-shadow: var(--box-shadow);
		background-color: #fff;
		z-index: 80;

		button {
			width: 100%;
			border: 0;
			background-color: transparent;
			cursor: pointer;
			padding: 0.5em;
			font-family: "Lexend", sans-serif;

			transition: background-color 0.5s ease, color 0.5s ease;

			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 5px;
			
			&:not(:last-child) {
				border-bottom: 1px solid #ccc;
			}

			&:hover, &:active {
				background-color: #f5f5f5;
				color: var(--tertiary);
			}
		}
	}
}
</style>
